var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(/* Interval indicator */)+" "),_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.getLogs}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Reload")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"options":_vm.options,"headers":_vm.headers,"items":_vm.statusLogs,"single-expand":"","show-expand":"","item-key":"uuid","expanded":_vm.expanded,"server-items-length":_vm.totallogs,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.before ? item.before.fullname : "-")+" ")]}},{key:"item.before",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold cursor-pointer",style:({ color: _vm.colorShowS(item.beforePolizeStatus) })},[_vm._v(_vm._s(_vm.statusShowS(item.beforePolizeStatus)))])]}},{key:"item.after",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold cursor-pointer",style:({ color: _vm.colorShowS(item.polizeStatus) })},[_vm._v(_vm._s(_vm.statusShowS(item.polizeStatus)))])]}},{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold cursor-pointer",style:({ color: _vm.colorShowS(item) })},[_vm._v(_vm._s(_vm.statusShowS(item)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"mt-2 mb-3"},[_c('strong',[_vm._v("Note:")])]),_vm._v(" "+_vm._s(item.changes ? item.changes.noteForStatusChange : "-")+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"mt-2 mb-3"},[_c('strong',[_vm._v("Created by:")])]),_vm._v(" "+_vm._s(item.createdBy ? item.createdBy.fullname : "-")+" ")])],1)],1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(
          item.name != null &&
          item.lastName != null &&
          item.paymentAmount == null
        )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.convertPatient(item)}}},on),[_vm._v(" mdi-account-convert")])]}}],null,true)},[_c('span',[_vm._v("Convert to Patient")])])]:_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }